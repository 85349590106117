.nav-mobile {
    margin-left: 20px;
    @include lg {
        display: none;
    }
    &__button {
        position: relative;
        cursor: pointer;
        padding: 4px;
    }
    &__button-item {
        width: 12px;
        height: 2px;
        border-radius: 1px;
        background: #fff;
        margin-top: 2px;
        .fixed & {
            background: #000;
        }
    }
    &__inner {
        padding: 16px;
        position: fixed;
        z-index: 99;
        background: #000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        will-change: transform;
        transition: transform 0.1s ease-in-out 0s;
        transform: translateX(100%);
        &.open {
            transform: translateX(0);
        }
    }
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__center {
        padding-top: 24px;
        flex: 1 1 auto;
    }

    &__close {
        position: relative;
        width: 16px;
        height: 16px;
        @include background-image(close);
        background-position: center;
        background-size: 12px auto;
        cursor: pointer;
    }
    .logo {
        @include background-image(logo);
    }
    .btn {
        width: 100%;
    }
}
