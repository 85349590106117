$media-query: 768px 1024px 1400px 1800px;

$sizeMatrix: 
  8px, 8px, 8px, 8px, 8px, //row 0 8px
  16px, 16px, 16px, 12px, 12px, //row 1 16px //complete
  24px, 24px, 24px, 24px, 16px, //row 2 24px //complete
  32px, 24px, 24px, 8px, 8px, //row 3 32px
  48px, 56px, 48px, 24px, 24px, //row 4 48px
  80px, 64px, 56px, 32px, 24px,//row 5 56px
  64px, 48px, 40px, 40px, 24px, //row 6 64px //complete
  104px, 88px, 72px, 40px, 40px ,//row 7 72px
  96px, 72px, 64px, 64px, 32px, //row 8 96px //complete
  128px, 104px, 80px, 80px, 56px, //row 9 128px //complete
  192px, 180px, 168px, 168px, 120px, //row 10 192px //complete
  40px, 32px, 24px, 24px, 16px; //row 11 40px

@mixin sizeMatrix($propName, $row: 0) {
  $startIndex: $row * 5;
  #{$propName}: nth($sizeMatrix, $startIndex + 5);
  @each $current-media-query in $media-query {
    $i: index($media-query, $current-media-query);
    @media (min-width: $current-media-query) {
      #{$propName}: nth($sizeMatrix, $startIndex + 5 - $i);
    }
  }
}