.media {
    @include md {
        display: flex;
        align-items: center;
    }

    &__header {
        padding-right: 100px;
        @include xl {
            padding-right: 200px;
        }
    }
    &__cnt {
        margin-top: 40px;
        @include md {
            margin-top: 0;
            display: flex;
        }
    }
    &__cnt-left {
        padding-right: 0px;
        @include md {
            padding-right: 50px;
        }

        @include xl {
            padding-right: 125px;
        }

    }
}
.media-item {
    margin-top: 40px;
    margin-bottom: 40px;
    &__description {
        margin-top: 10px;
        max-width: 480px;
    }
}

.link {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    &:last-child {
        margin-right: 0;
    }
    &__ico {
        width: 24px;
        flex: 0 0 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        &_main {
            @include background-image(ico-main);
        }
        &_blog {
            @include background-image(ico-blog);
        }
        &_notion {
            @include background-image(ico-notion);
        }
        &_tg {
            @include background-image(ico-tg);
        }
        &_vk {
            @include background-image(ico-vk);
        }
        &_inst {
            @include background-image(ico-inst);
        }
        &_youtube {
            width: 25px;
            flex: 0 0 25px;
            @include background-image(ico-youtube);
        }
        &_discord {
            @include background-image(ico-discord);
        }
        &_twitter {
            //@include background-image(ico-twitter);
            @include background-image(ico-x);

        }
        &_shop {
            @include background-image(ico-shop);
        }
        &_mail {
            @include background-image(ico-mail);
        }
        &_crew3{
            @include background-image(ico-crew3);
        }
        &_prime{
            @include background-image(ico-prime);
        }
        &_getgems{
            @include background-image(ico-getgems);
        }
        &_x {
            @include background-image(ico-x);
        }
    }
    &__txt {
        display: flex;
        align-items: center;
        height: 24px;
        line-height: 24px;
        background: #fff;
        border-radius: 12px;
        padding-left: 10px;
        padding-right: 10px;
        text-decoration: none;
        color: #000;
        // margin-left: 10px;
        &:after {
            display: block;
            content: '';
            width: 8px;
            height: 8px;
            flex: 0 0 8px;
            @include background-image(arrow-top);
            margin-left: 10px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}
