.index {
    @include md {
        display: flex;
        flex-wrap: nowrap;
    }

    &__left {
        position: relative;
        z-index: 2;
        transition: all 0.5s ease-in-out 0s;
        align-self: start;
        &_active {
            @media all and (min-width: 768px) {
                padding-top: 0 !important;
                transform: translateY(-100%);
                padding-bottom: 20px;
                .index__header {
                    transform: scale(0.5);
                }
            }
        }
        @include md {
            padding-top: 170px;
        }
    }
    &__center {
        display: none;
        @include md {
            display: block;
            flex: 0 0 50px;
            width: 50px;
        }
        @include xl {
            flex: 0 0 150px;
            width: 150px;
        }
        @include xxl {
            flex: 0 0 200px;
            width: 200px;
        }
    }
    &__right {
        width: auto;
        min-width: 0;
        position: relative;
        z-index: 1;
        margin-top: 40px;
        @include md {
            margin-top: 0;
        }
    }
    &__nav-message {
        margin-top: 20px;
        @include lg {
            position: absolute;
            right: 0;
            bottom: -50px;
            transform: translateY(100%);
            width: 100%;
            z-index: 2;
        }

        @include xl {
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }
    }
    &__message {
        flex: 1 1 auto;
        transition: opacity 0.2s ease-in 0s;
        padding-right: 20px;
        &.hide {
            opacity: 0;
        }
    }

    &__navigation {
        flex: 0 0 auto;
        display: flex;

        &__right {
            margin-left: auto;
        }
    }
    &__navigation-item {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        border-radius: 15px;
        @include background-image(arrow-left);
        background-color: #fff;
        background-size: auto 8px;
        cursor: pointer;
        margin-right: 10px;
        &_next {
            transform: rotate(180deg);
        }
        &.swiper-button-lock {
            display: none;
        }
        &.swiper-button-disabled {
            opacity: 0.6;
            cursor: default;
        }
    }

    &__right-center {
        position: relative;
    }
    &__right-bottom {
        position: relative;
        width: 100%;
        z-index: 3;
        max-width: 580px;
        margin-top: 30px;
        @include lg {
            margin-top: 50px;
        }
        @include xl {
            max-width: 650px;
        }
        @include xxl {
            max-width: 700px;
        }
    }
    &__header {
        transform-origin: bottom left;
        text-transform: uppercase;
    }
    &__header-line {
        position: relative;
        display: inline-block;
        width: auto;
    }
    &__header-line-right {
        @include t-neo-light-20;
        position: absolute;
        left: 100%;
        top: 0;
        color: #fff;
    }
}

.swiper {
    overflow: visible !important;
}
.swiper-slide {
    width: auto !important;
    height: auto !important;
}
.product {
    position: relative;
    width: 180px;
    flex: 0 0 180px;
    height: 100%;
    border: 1px solid $colorGrey2;
    background: #18191c;

    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;

    &_disabled {
        .product__ico {
            filter: grayscale(1);
        }
    }

    &:hover {
        .not-touch-device & {
            .product__ico {
                filter: brightness(0%) grayscale(0);
                &.product__ico_9 {
                    filter: brightness(100%) grayscale(1) contrast(150%);
                }
                &.product__ico_12 {
                    filter: brightness(100%) grayscale(1) contrast(150%);
                }
            }
            .product__back {
                opacity: 1;
            }
            .product__link {
                transform: translateX(15px);
            }
        }
    }
    &_active {
        .product__ico {
            filter: brightness(0%) grayscale(0);
            &.product__ico_9 {
                filter: brightness(100%) grayscale(1) contrast(150%);
            }
            &.product__ico_12 {
                filter: brightness(100%) grayscale(1) contrast(150%);
            }
        }
        .product__back {
            opacity: 1;
        }
        .product__link {
            transform: translateX(15px);
        }
    }

    &__title {
        text-align: center;
        pointer-events: none;
        @include t-neo-light-28;
        height: 75px;
    }
    &__ico {
        display: block;
        width: 90px;
        height: 90px;
        flex: 0 0 90px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: all 0.1s ease-in-out 0s;
        pointer-events: none;
        &_1 {
            @include background-image(logo-1);
        }
        &_2 {
            @include background-image(logo-2);
        }
        &_3 {
            @include background-image(logo-3);
        }
        &_4 {
            @include background-image(logo-4);
        }
        &_5 {
            @include background-image(logo-5);
        }
        &_6 {
            @include background-image(logo-6);
        }
        &_7 {
            @include background-image(logo-7);
        }
        &_8 {
            @include background-image(logo-8);
            width: 100px;
        }
        &_9 {
            @include background-image(logo-9);
            scale: 1.2;
        }
        &_10 {
            @include background-image(logo-10);
        }
        &_11 {
            @include background-image(logo-11);
        }
        &_12 {
            @include background-image(logo-13);
        }

        margin-top: 30px;
        @media all and (min-height: 800px) {
            margin-top: 60px;
        }
    }
    &__link {
        display: block;
        width: 90px;
        @include background-image(arrow-long);
        height: 18px;
        flex: 0 0 18px;
        pointer-events: none;
        transition: all 0.1s ease-in-out 0s;
        margin-top: 60px;
        @media all and (min-height: 800px) {
            margin-top: 100px;
        }
    }
    &__back {
        position: absolute;
        z-index: -1;
        width: 110%;
        height: 110%;
        left: -5%;
        top: -5%;
        background-size: cover;
        transition: opacity 0.1s ease-in-out 0s;
        opacity: 0;
        pointer-events: none;

        &_1 {
            background: linear-gradient(180deg, #1d0ec5 0%, #82c1ff 100%);
        }
        &_2 {
            background: linear-gradient(180deg, #d6a11a 0%, #faeea3 100%);
        }
        &_3 {
            background: linear-gradient(180deg, #1ebb82 0%, #6bf5c4 100%);
        }
        &_4 {
            background: linear-gradient(180deg, #5616de 0%, #9868ff 100%);
        }
        &_5 {
            background: linear-gradient(180deg, #d5272c 0%, #f6586b 100%);
        }
        &_6 {
            background-image: url('../../assets/img/back-6.jpg');
        }
        &_7 {
            background: linear-gradient(180deg, #da3fdd 0%, #ff507a 100%);
        }
        &_8 {
            // background: linear-gradient(180deg, #ff6f6f 0%, #a70027 100%);
            background: linear-gradient(180deg, #8f8f8f 0%, #272727 100%);
        }
        &_9 {
            //background: linear-gradient(180deg, #5616de 0%, #9868ff 100%);
            background: linear-gradient(180deg, #ef8814 0%, #fdbc20 51.48%, #f1930d 100%);
        }
        &_10 {
            background: linear-gradient(180deg, #1e5928 0%, #c7f221 59.5%, #a9d012 100%, #2e883d 100%);
        }
        &_11 {
            background: linear-gradient(180deg, #a292ff 0%, #806eed 100%);
        }
    }
}

.product-description {
    height: 0;
    overflow: hidden;
    &_active {
        height: auto;
    }
    &__txt {
        @include animation-base;
        opacity: 0;
        transform: translateY(5px);
        .product-description_active & {
            opacity: 1;
            transform: none;
        }
    }
    &__links {
        display: flex;
        flex-wrap: wrap;
        @include animation-base;
        opacity: 0;
        transform: translateX(5px);
        .product-description_active & {
            opacity: 1;
            transform: none;
        }
    }
    .link {
        margin-bottom: 30px;
        margin-right: 30px;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
    }
}
