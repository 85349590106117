$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1400px;
$xxl: 1600px;

@mixin sm {
    @media all and (min-width: $sm) {
        @content;
    }
}
@mixin md {
    @media all and (min-width: $md) {
        @content;
    }
}
@mixin lg {
    @media all and (min-width: $lg) {
        @content;
    }
}
@mixin xl {
    @media all and (min-width: $xl) {
        @content;
    }
}
@mixin xxl {
    @media all and (min-width: $xxl) {
        @content;
    }
}
