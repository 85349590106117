.about {
    &__top {
        display: flex;
        align-items: center;
    }

    &__left {
        flex: 1 1 auto;
    }

    &__right {
        flex: 0 0 auto;
    }
}

.tab {
    &__links {
        @include md {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        color: $colorGrey;
        @include t-header-1;
        margin-top: 10px;
        @include md {
            margin-top: 0;
        }

        span {
            cursor: pointer;
            &.active {
                @include headerBack;
            }
        }
        &:after {
            display: none;
            content: '';
            width: 10px;
            height: 10px;
            flex: 0 0 10px;
            background: $colorGrey;
            margin-left: 30px;
            margin-right: 30px;
            @include md {
                display: block;
            }
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
    &__index {
        padding-left: 20px;
        @include t-neo-light-28;
        display: none;
        @include lg {
            display: block;
        }
    }
    &__items {
        margin-top: 40px;
        max-width: 1350px;
        @media all and(min-height:800px) {
            margin-top: 90px;
        }
    }
    &__item {
        height: 0;
        overflow: hidden;
        &_active {
            height: auto;

            &.partners-item {
                overflow: visible;
            }
        }
    }
    &__txt {
        @include t-reg-34;
        @include animation-base;
        opacity: 0;
        transform: translateY(5px);
        .tab__item_active & {
            opacity: 1;
            transform: none;
        }
    }
} //tab

.ceo {
    text-align: -webkit-center;
    opacity: 0;
    @include animation-base;
    transform: translateY(5px);
    @include xl {
        text-align: unset;
        display: flex;
    }
    .tab__item_active & {
        opacity: 1;
        transform: none;
    }

    &__img {
        display: block;
        max-width: 220px;
        border-radius: 20px;
    }
    &__right {
        margin-top: 20px;
        @include xl {
            margin-top: 0;
            padding-left: 40px;
        }

        &.mr-90 {
            @include xl {
                margin-right: 90px;
            }
        }
    }

    &__index {
        @include t-neo-light-14;
    }
    &__name {
        margin-top: 20px;
        @include t-reg-34;
    }
    &__txt {
        margin-top: 30px;
        max-width: 410px;
        a {
            color: #fff;
        }
    }
}

.partners {
    margin-top: 30px;
    opacity: 0;
    @include animation-base;
    transform: translateY(5px);
    display: flex;
    flex-wrap: wrap;

    .tab__item_active & {
        opacity: 1;
        transform: none;
    }
}
.partner {
    margin-top: 30px;
    text-align: center;
    margin-right: 40px;
    width: 100px;
    flex: 0 0 100px;
    a {
        color: #fff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    &:last-child {
        margin-right: 0;
    }
    &__ico {
        width: 100px;
        height: 100px;

        &_1 {
            @include background-image(ico-partner-1_color);
        }
        &_2 {
            @include background-image(ico-partner-2_color);
        }
        &_3 {
            @include background-image(ico-partner-3_color);
        }
        &_4 {
            @include background-image(ico-partner-4_color);
        }
        &_5 {
            @include background-image(ico-partner-5_color);
        }
        &_6 {
            @include background-image(ico-partner-6_color);
        }
        &_7 {
            @include background-image(ico-partner-7_color);
        }
        &_8 {
            @include background-image(ico-partner-8_color);
        }
        &_9 {
            @include background-image(ico-partner-9_color);
        }
        &_10 {
            @include background-image(ico-partner-10_color);
        }
        &_11 {
            @include background-image(ico-partner-11_color);
        }
        &_12 {
            @include background-image(ico-partner-12_color);
        }
        &_13 {
            @include background-image(ico-partner-13_color);
        }
        &_14 {
            @include background-image(ico-partner-14_color);
        }
        &_15 {
            @include background-image(ico-partner-15_color);
        }
        &_16 {
            @include background-image(ico-partner-16_color);
        }
        &_17 {
            @include background-image(ico-partner-17_color);
        }
    }
    &__title {
        margin-top: 15px;
    }
}
