.contacts {
    @include lg {
        display: flex;
        flex-wrap: wrap;
    }
    &__col {
        padding-right: 50px;

        @include xl {
            padding-right: 200px;
        }
        &:last-child {
            padding-right: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            @include lg {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    &__header {
        @include lg {
            height: 120px;
        }
    }
    &__link {
        display: block;
        color: #fff;
        text-decoration: none;
        line-height: 180%;
        &:hover {
            .not-touch-device & {
                text-decoration: underline;
            }
        }
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    &__item {
        margin-top: 40px;
        &:first-child {
            margin-top: 0;
        }
    }
    &__row-inner {
        display: flex;
        flex-wrap: wrap;
    }
    &__col-inner {
        padding-right: 60px;
        padding-top: 20px;
        padding-bottom: 20px;
        &:last-child {
            padding-right: 0;
        }
    }
}
