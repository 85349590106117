body,
html {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #18191c;
    color: #fff;
    @include t-reg;
}

body {
    // for banner
    overflow: hidden;

    width: 100%;
    min-height: 100vh;
    background-image: url('../img/back-mobile.jpg');
    background-position: center;
    background-size: cover;
    @include md {
        background: none;
    }

    @media (max-width: 768px) {
        min-height: 120vh;
    }
}

::-moz-selection {
    background: #eb2629;
    /* Code for Firefox */
    color: #fff;
}

::selection {
    background: #eb2629;
    color: #fff;
}

br {
    display: none;
    @include sm {
        display: block;
    }
}
.app {
    position: relative;
    z-index: 2;
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;

    @include lg {
        padding: 40px;
    }
    @include xl {
        padding: 10vh;
    }
}
.main {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    margin-top: 40px;
    @include md {
        margin-top: 10vh;
    }
    @include xxl {
        margin-top: 15vh;
    }
    .media &,
    .contacts & {
        flex: 0 0 auto;
        margin-top: 20px;
    }
}

.logo {
    width: 170px * 0.6;
    height: 25px * 0.6;
    cursor: pointer;
    @include background-image(logo);
    @include sm {
        width: 170px * 0.8;
        height: 25px * 0.8;
    }
    @include md {
        width: 170px;
        height: 25px;
    }
}

.page {
    display: none;
    &_active {
        display: block;
    }
}
.nav {
    position: fixed;
    z-index: 4;
    top: 80vh;
    left: 0px;
    display: none;
    width: 150px;
    padding-bottom: 10vh;
    padding-left: 10vh;
    @media all and (max-height: 800px) {
        padding-bottom: 10vh;
        padding-left: 10vh;
    }
    @include lg {
        display: block;
    }
    @include xl {
    }
    .about & {
        position: sticky;
        padding-bottom: 0;
        padding-left: 0;
    }

    &__item {
        margin-top: 10px;
        cursor: pointer;
        text-transform: lowercase;
        transform: translateX(10px);
        transition: all 0.2s ease-in-out 0s;
        white-space: nowrap;
        &_mobile {
            padding-top: 10px;
            padding-bottom: 10px;
            color: #fff;
            @include t-header-1;
        }
        &_active {
            letter-spacing: 2px;
            transform: translateX(0);
        }
        &:first-child {
            body.index & {
                letter-spacing: 2px;
                transform: translateX(0);
            }
        }
    }
}

@mixin headerBack {
    background: linear-gradient(180deg, #eb2629 0%, #eb2629 59.9%, #eb2629 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    user-select: none;
}
.header {
    @include t-header-1;
    @include headerBack;
}

.copyright {
    position: fixed;
    bottom: 0;
    left: 100%;
    display: none;
    color: #fff;
    transform: rotate(270deg) translateX(40px) translateY(-40px);
    transform-origin: bottom left;
    transition: all 0.2s ease-in-out 0s;
    white-space: nowrap;
    will-change: transform;
    @include t-reg-10;
    @include lg {
        display: block;
    }
    @include xl {
        transform: rotate(270deg) translateX(10vh) translateY(-10vh);
    }

    .index & {
        opacity: 0;
        transform: translateY(100%) rotate(270deg);
    }

    .hire & {
        opacity: 0;
        transform: translateY(100%) rotate(270deg);
    }
}

.hideTouch,
.showTouch {
    display: none;
}
.not-touch-device {
    .hideTouch {
        display: initial;
    }
}
.touch-device {
    .showTouch {
        display: initial;
    }
}

.lang {
    &__item {
        position: relative;
        margin-left: 10px;
        color: #757575;
        text-decoration: none;
        text-transform: uppercase;

        &_active {
            color: #fff;
            &:after {
                position: absolute;
                bottom: -4px;
                left: 0;
                display: block;
                width: 100%;
                height: 2px;
                background: #eb2629;
                content: '';
            }
        }
    }
}

.mustang {
    position: fixed;
    z-index: 1000;
    right: 100px;
    bottom: 30px;

    @media (max-width: 768px) {
        right: 10px;
    }

    // &-event {
    //     z-index: 1000;
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     min-width: 100vw;
    //     min-height: 100vh;
    //     width: 100%;
    //     height: 100%;
    //     // position: relative;
    //     display: flex;
    //     justify-content: end;
    //     position: fixed;
    // }

    &__container {
        position: relative;
        width: 384px;
        height: 93px;
        border: 1px solid #dadada;
        background: #18191c;
        border-radius: 18px;

        opacity: 1;
        transition: opacity 0.3s ease;

        @media (max-width: 420px) {
            width: calc(100vw - 20px);
        }

        &__cross {
            @media (min-width: 769px) {
                display: none;
            }
            position: absolute;
            top: 20px;
            left: 10px;
            margin-top: -40px;

            cursor: pointer;
            opacity: 1;
            transition: opacity 0.3s ease;
            &:before,
            &:after {
                position: absolute;
                width: 24px;
                height: 4px;
                background: gray;
                content: '';
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        &__box {
            position: absolute;
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(0deg);
            &._1 {
                top: 25px;
                left: 46px;
                width: 27px;
                height: 27.5px;
                background-image: url('../img/mustang-box-1.png');
                // animation: box-1 3s infinite ease-in-out;
                // animation: box-1 5s infinite linear;
            }
            &._2 {
                top: 25px;
                left: 38px;
                width: 27px;
                height: 27px;
                background-image: url('../img/mustang-box-2.png');
                // animation: box-2 3.5s infinite ease-in-out;
                // animation: box-2 5s infinite ease-out;
            }
            &._3 {
                top: 25px;
                left: 20px;
                width: 21px;
                height: 24px;
                background-image: url('../img/mustang-box-3.png');
                // animation: box-3 4.5s infinite ease-in-out;
                // animation: box-3 5s infinite linear;
            }
        }

        &__mustang {
            position: absolute;
            top: -61px;
            left: 5px;
            width: 183.839px;
            height: 152.085px;
            background-image: url('../img/mustang.png');
            background-repeat: no-repeat;
            background-size: contain;
            // transform: rotate(3.977deg);
            transform: rotate(1.817deg);
        }

        &__text {
            position: absolute;
            top: 15px;
            right: 18px;
            width: 199px;
            color: #fff;
            font-family: Montserrat, sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 136%;
            text-align: right;

            text-align: right;

            @media (max-width: 420px) {
                top: 18px;
                font-size: 11px;
            }

            > b {
                color: #fac70e;
                font-weight: 700;
            }
            > span {
                font-weight: 700;
            }
            > br {
                display: block;
            }
        }
    }
}

@keyframes hoverAnimationIn {
    from {
        left: 5px;
    }
    to {
        left: 49px;
    }
}

@keyframes hoverAnimationOut {
    from {
        left: 49px;
    }
    to {
        left: 5px;
    }
}

@keyframes hoverInBox1 {
    from {
        top: 25px;
        left: 46px;
    }
    to {
        top: 14px;
        left: 34px;
    }
}

@keyframes hoverOutBox1 {
    from {
        top: 14px;
        left: 34px;
    }
    to {
        top: 25px;
        left: 46px;
    }
}

@keyframes hoverInBox2 {
    from {
        top: 25px;
        left: 38px;
    }
    to {
        top: 49px;
        left: 31px;
    }
}

@keyframes hoverOutBox2 {
    from {
        top: 49px;
        left: 31px;
    }
    to {
        top: 25px;
        left: 38px;
    }
}

@keyframes hoverInBox3 {
    from {
        top: 25px;
        left: 20px;
    }
    to {
        top: 40px;
        left: 9px;
    }
}

@keyframes hoverOutBox3 {
    from {
        top: 40px;
        left: 9px;
    }
    to {
        top: 25px;
        left: 20px;
    }
}

// @keyframes hoverInBox1 {
//     from {
//         top: 14px;
//         left: 46px;
//     }
//     50% {
//         top: 0px;
//         left: 38px;
//     }
//     to {
//         top: 14px;
//         left: 34px;
//     }
// }
// @keyframes hoverOutBox1 {
//     from {
//         left: 34px;
//     }
//     to {
//         left: 46px;
//     }
// }

// @keyframes hoverInBox2 {
//     from {
//         top: 49px;
//         left: 42px;
//     }
//     50% {
//         top: 25px;
//     }
//     to {
//         top: 49px;
//         left: 31px;
//     }
// }

// @keyframes hoverOutBox2 {
//     from {
//         top: 49px;
//         left: 31px;
//     }
//     50% {
//         top: 35px;
//     }
//     to {
//         top: 49px;
//         left: 42px;
//     }
// }

// @keyframes hoverInBox3 {
//     from {
//         top: 40px;
//         left: 20px;
//     }
//     50% {
//         top: 20px;
//     }
//     to {
//         top: 40px;
//         left: 9px;
//     }
// }

// @keyframes hoverOutBox3 {
//     from {
//         top: 40px;
//         left: 9px;
//     }
//     50% {
//         top: 20px;
//     }
//     to {
//         top: 40px;
//         left: 20px;
//     }
// }

.birthday {
    position: absolute;
    z-index: 1000;
    // right: 100px;
    // bottom: 30px;
    width: 414px;
    height: 120px;
    background-repeat: no-repeat;
    background-size: cover;

    &_birthday {
        background-image: url('../img/new-year.png');
    }

    &_friday {
        background-image: url('../img/new-year.png');
        background-size: contain;
    }

    cursor: pointer;

    @media (max-width: 1919px) and (min-width: 1368px) {
        // right: 90px;

        width: 373px;
        height: 108px;
    }

    @media (max-width: 1367px) {
        // right: 50px;
        width: 311px;
        height: 90px;
    }

    @media (max-width: 768px) {
        // bottom: 10px;
        width: 289px;
        height: 84px;
    }

    &__wrapper {
        position: absolute;
        z-index: 1000;

        right: 100px;
        bottom: 30px;
        width: 414px;
        height: 120px;
        background-repeat: no-repeat;
        background-size: cover;

        &_hidden {
            display: none;
        }

        cursor: pointer;
        transform: translateY(150%);

        &.show {
            animation: slide-up 2s ease-in forwards;
        }

        &.close {
            animation: slide-down 2s ease-in forwards;
        }

        transition: scale 1.5s ease;

        &:hover {
            @media (width > 768px) {
                scale: 1.1;
            }
        }

        @media (max-width: 1919px) and (min-width: 1368px) {
            right: 90px;

            width: 373px;
            height: 108px;
        }

        @media (max-width: 1367px) {
            right: 50px;
            width: 311px;
            height: 90px;
        }

        @media (max-width: 768px) {
            bottom: 10px;
            width: 289px;
            height: 84px;
        }
    }

    &__close {
        position: absolute;
        z-index: 10000;
        top: 35px;
        right: 10px;
        cursor: pointer;

        transition: opacity 0.3s ease;

        &:hover {
            @media (width > 768px) {
                opacity: 0.8;
            }
        }

        @media (max-width: 1367px) {
            top: 25px;
            right: 10px;
        }

        @media (width <= 769px) {
            top: 23px;
            right: 10px;
        }

        @media (width <= 520px) {
            top: 25px;
            right: 10px;
        }

        &_friday {
            top: 15px;

            @media (max-width: 1367px) {
                top: 10px;
            }
        }
    }
}

@keyframes slide-up {
    from {
        transform: translateY(150%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(150%);
    }
}
