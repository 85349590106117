#back-1 {
    display: none;
    @include md {
        display: block;
        @include background-image(back-1);
    }
}

#back-2 {
    display: none;
    @include md {
        display: block;
        @include background-image(back-2);
    }
}

#back-0,
#back-1,
#back-2 {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    will-change: transform;
    transition: all 0.5s linear 0s;
    opacity: 0.6;
}

#back-0 {
    display: none;
    z-index: 0;
    opacity: 1;
    @include md {
        display: block;
        background-image: url('../img/back.jpg');
    }
}
