@mixin animation-base {
    will-change: transform;
    transition: all 0.5s ease-in 0s;
    transition-property: transform, opacity;
}

.logo,
.lang,
.nav,
.main {
    @include animation-base;
    opacity: 0;
    transform: translateX(-70px);
    .loaded & {
        opacity: 1;
        transform: none;
    }
}

.router-out-left {
    @include animation-base;
    .router-out & {
        opacity: 0;
        transform: translateX(-70px);
    }
}

.router-out-right {
    @include animation-base;
    .router-out & {
        opacity: 0;
        transform: translateX(-70px);
    }
}

.router-out-down {
    @include animation-base;
    .router-out & {
        opacity: 0;
        transform: translateX(-70px);
    }
}

.router-out-up {
    @include animation-base;
    .router-out & {
        opacity: 0;
        transform: translateX(-70px);
    }
}
