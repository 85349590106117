.flex {
  @include flexbox();
  //Flex justify
  &--j__center {
    @include justify-content(center);
  }
  &--j__end {
    @include justify-content(flex-end);
  }
  &--j__start {
    @include justify-content(flex-start);
  }
  &--j__spaceB {
    @include justify-content(space-between);
    @include xl {
      margin-bottom: 20px;
    }
    @include xxl {
       margin-bottom: 0px;
    }
   
  }
  &--j__spaceA {
    @include justify-content(space-around);
  }

  //align-items
  &--a__center {
    @include align-items(center);
  }
  &--a__end {
    @include align-items(flex-end);
  }
  &--a__baseline {
    @include align-items(baseline);
  }
  //Direction
  &--d__column {
    @include flex-direction(column);
  }
  &--d__columnR {
    @include flex-direction(column-reverse);
  }
  &--d__rowR {
    @include flex-direction(row-reverse);
  }

  &--g__1 {
    @include flex-grow();
  }

  &--s__0 {
    @include flex-shrink(0);
  }

  &--b__auto {
    @include flex-basis();
  }
  &--1 {
    @include flex(1);
  }

  &--wrap {
    @include flex-wrap(wrap);
  }

  &--as__end {
    @include align-self(flex-end);
  }

  &--as__center {
    @include align-self(center);
  }
}
