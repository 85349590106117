.hire {
    a {
        color: white;
    }
    a:hover {
        opacity: 0.75;
    }

    display: flex;
    flex-wrap: nowrap;
    @media screen and (max-width: 1024px) {
        display: block;
    }
    @include md {
        display: flex;
        flex-wrap: nowrap;
    }

    &__left {
        position: relative;
        z-index: 2;
        transition: all 0.5s ease-in-out 0s;
        align-self: start;
        @media screen and (max-width: 1024px) {
            padding-top: 0 !important;
            padding-bottom: 20px;
        }
        @media screen and (min-width: 764px) and (max-width: 1024px) {
            .hire__header {
                transform: scale(0.5);
            }
        }

        &.prime {
            width: 25px;
            flex: 0 0 25px;
            @include background-image(ico-prime);
        }

        @include md {
            padding-top: 170px;
        }
    }
    &__center {
        display: none;
        @include md {
            display: block;
            flex: 0 0 50px;
            width: 50px;
        }
        @include xl {
            flex: 0 0 150px;
            width: 150px;
        }
        @include xxl {
            flex: 0 0 200px;
            width: 200px;
        }
        @media screen and (max-width: 1024px) {
            margin-left: -200px;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin-left: -170px;
        }
    }
    &__right {
        margin-left: -435px;
        transition: 0.3s ease-in-out;
        width: 1400px;
        height: 450px;
        min-width: 0;
        position: relative;
        z-index: 1;
        margin-top: 40px;
        @include md {
            margin-top: 0;
        }
        &-center {
            overflow: hidden;
            height: 450px;
            width: 115%;
        }
        @media screen and (max-width: 1160px) and (min-width: 1025px) {
            margin-left: -360px;
        }
        @media screen and (max-width: 1024px) {
            margin-left: -280px;
        }
        @media screen and (max-width: 500px) {
            margin-left: -290px;
            z-index: 10;
        }
    }
    &__nav-message {
        margin-right: 90px;
        margin-top: 20px;
        z-index: 1;
        @include lg {
            position: absolute;
            right: 0;
            bottom: 30px;
            transform: translateY(100%);
            z-hire: 2;
        }

        @include xl {
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }
    }
    &__message {
        flex: 1 1 auto;
        transition: opacity 0.2s ease-in 0s;
        padding-right: 20px;
        &.hide_hire {
            opacity: 0;
        }
    }

    &__navigation {
        flex: 0 0 auto;
        display: flex;
        margin-right: 100px;
    }
    &__navigation-item {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        border-radius: 15px;
        @include background-image(arrow-left);
        background-color: #fff;
        background-size: auto 8px;
        cursor: pointer;
        margin-right: 10px;
        &_next {
            transform: rotate(180deg);
        }
        &.swiper-button-lock {
            display: none;
        }
        &.swiper-button-disabled {
            opacity: 0.6;
            cursor: default;
        }
    }

    &__right-center {
        position: relative;
    }
    &__right-bottom {
        margin-top: -50px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 20;
        max-width: 850px;
        padding-top: 30px;
        margin-left: 435px;

        @media screen and (max-width: 576px) {
            margin-top: -130px;
            width: 85vw;
            left: -150px;
            font-size: 12px;
            margin-bottom: 50px;
        }
        @media screen and (max-width: 460px) {
            margin-top: -245px;
        }
        @include sm {
            left: -150px;
            width: 85vw;
            margin-top: 0;
        }
        @media screen and (min-width: 576px) and (max-width: 768px) {
            left: -150px;
            margin-top: -100px;
        }
        @media screen and (min-width: 769px) {
            margin-top: -100px;
        }
        @include lg {
            margin-top: -150px;
        }
        @include xl {
            max-width: 580px;
            margin-top: -200px;
        }
        @include xxl {
            max-width: 850px;
            margin-top: -200px;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            width: 580px;
            left: -150px;
            margin-top: -100px;
        }
        @media screen and (min-width: 1025px) and (max-width: 1400px) {
            width: 580px;
        }
        @media screen and (min-width: 1401px) and (max-width: 1600) {
            max-width: 0;
            width: 850px;
        }
    }
    &__header {
        z-index: -1;
        transform-origin: bottom left;
        text-transform: uppercase;
        &-questions {
            margin-top: 30px;
            text-transform: none;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            letter-spacing: 0.02em;
            &-link > a {
                text-decoration: none;
                color: #fff;
                text-transform: none;
            }
        }
    }
    &__header-line {
        position: relative;
        display: inline-block;
    }

    &__header-line-right {
        @include t-neo-light-20;
        position: absolute;
        left: 100%;
        top: 0;
        color: #fff;
    }
}
.swiper.hire {
    width: 1420px !important;
    height: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.hire-page {
    position: relative;
    width: 268px;
    height: 396px;
    flex: 0 0 180px;
    border: 1px solid $colorGrey2;
    background: #18191c;

    border-radius: 20px;
    padding-top: 39px;
    padding-bottom: 39px;
    padding-left: 36px;
    padding-right: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
    opacity: 1;
    &.swap-hidden {
        // transition: all 0.2s ease;
        transition: all 0s ease;
        opacity: 0;
    }
    &.swap-hidden-active {
        // transition: all 0.2s ease;
        transition: all 0.2s ease;
        opacity: 0;
    }
    &.active {
        @include animation-base;
        transition: 2s ease;
        animation: cardXl 0.5s ease;
        animation-fill-mode: forwards;
        @media screen and (max-width: 1600px) {
            animation: cardMd 0.5s ease;
            animation-fill-mode: forwards;
        }
        @media screen and (max-width: 765px) {
            animation: cardSm 0.5s ease;
            animation-fill-mode: forwards;
        }
    }

    @keyframes cardXl {
        0% {
            opacity: 1;
            // width: 268px;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 0;
            // width: 840px;
            margin-right: 572px;
        }
    }
    @keyframes cardMd {
        0% {
            opacity: 1;
            // width: 268px;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 0;
            margin-right: 302px;
            // width: 570px;
        }
    }
    @keyframes cardSm {
        0% {
            opacity: 1;
            margin-right: 0;
        }
        100% {
            opacity: 0;
            margin-right: calc(83vw - 268px);
        }
    }
    @keyframes cardBackXl {
        0% {
            opacity: 0;
            // width: 840px;
            margin-right: 572px;
        }
        100% {
            opacity: 1;
            // width: 268px;
            margin-right: 0px;
        }
    }
    @keyframes cardBackMd {
        0% {
            opacity: 0;
            margin-right: 302px;
        }
        100% {
            opacity: 1;
            margin-right: 0px;
        }
    }
    @keyframes cardBackSm {
        0% {
            opacity: 0;
            margin-right: calc(83vw - 268px);
        }
        100% {
            opacity: 1;
            margin-right: 0;
        }
    }

    &_disabled {
        .hire-page__ico {
            filter: grayscale(1);
        }
        @include animation-base;
        transition: 2s ease;

        @media screen and (min-width: 1601px) {
            animation: cardBackXl 0.5s ease;
        }
        @media screen and (max-width: 1600px) {
            animation: cardBackMd 0.5s ease;
        }
        @media screen and (max-width: 645px) {
            animation: cardBackSm 0.5s ease;
        }
        animation-fill-mode: forwards;
    }

    &:hover {
        .not-touch-device & {
            .hire-page__ico {
                filter: brightness(0%) grayscale(0);
            }
            .hire-page__back {
                opacity: 1;
            }
            .hire-page__link {
                transform: translateX(15px);
            }
        }
    }
    &_active {
        .hire-page__ico {
            filter: brightness(0%) grayscale(0);
        }
        .hire-page__back {
            opacity: 1;
        }
        .hire-page__link {
            transform: translateX(15px);
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-self: flex-start;
        gap: 11px;
        &__icon {
            width: 24px;
            flex: 0 0 24px;
            height: 24px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            &.crypton {
                @include background-image(ico-main);
            }

            &.yt {
                width: 25px;
                flex: 0 0 25px;
                @include background-image(ico-youtube);
            }

            &.prime {
                width: 25px;
                flex: 0 0 25px;
                @include background-image(ico-prime);
            }
        }
        &__text {
            // @include t-reg;
            // height: 17px;
            // font-size: 14px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.02em;
        }
    }
    &__title {
        display: block;
        width: 196px;
        flex: 0 0 90px;
        text-align: left;
        font-family: 'mach-light';
        font-size: 28px;
        > br {
            display: block;
        }
        margin-top: 30px;
        &-bottom {
            display: flex;
            height: 25px;
            justify-content: space-between;
            width: 100%;
            &-text {
                width: 100px;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.02em;
                line-height: 25px;
                border: 1px solid #ffffff;
                border-radius: 19px;
                text-align: center;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
    &__link {
        display: block;
        width: 55px;
        @include background-image(arrow-long);
        height: 25px;
        flex: 0 0 55px;
        pointer-events: none;
        transition: all 0.1s ease-in-out 0s;
        @media all and (min-height: 800px) {
            // margin-top: 100px;
        }
    }
    &__back {
        position: absolute;
        z-index: -1;
        width: 110%;
        height: 110%;
        left: -5%;
        top: -5%;
        background-size: cover;
        transition: opacity 0.1s ease-in-out 0s;
        opacity: 0;
        pointer-events: none;
        background: linear-gradient(180deg, #d5272c 0%, #f6586b 100%);
    }
    &-close {
        display: none;
        top: -20vh;
        position: absolute;
        left: -300px;
        width: 200vw;
        height: 110vh;
        margin: -8vh;
        z-index: 0;
        &.mobile {
            @media screen and (max-width: 420px) {
                // z-index: 1000 !important;
                width: 300vw;
            }
        }
    }
}

@keyframes cardDescrXl {
    0% {
        transform: scale(0.3, 0.45);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes cardDescrMd {
    0% {
        transform: scale(0.3, 0.45);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes cardDescrSm {
    0% {
        width: 268px;
        transform: scaleY(0.45);
    }
    100% {
        width: 85vw;
        transform: scaleY(1);
    }
}
@keyframes cardDescrBackXl {
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }
    100% {
        transform: scale(0.3, 0.45);

        opacity: 0;
    }
}
@keyframes cardDescrBackMd {
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }
    100% {
        transform: scale(0.3, 0.45);
        opacity: 0;
    }
}
@keyframes cardDescrBackSm {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.hire-page-description {
    height: 0;
    overflow: hidden;
    background: #18191c;
    opacity: 0;

    &_active {
        opacity: 1;
        display: block;
        min-height: 700px;
        height: auto;
        @include animation-base;
        animation: cardDescrXl 0.75s ease;
        border: 1px solid #dadada;
        border-radius: 20px;
        padding: 36px;
        overflow: hidden;
        max-height: 90vh;
        overflow: auto;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: 400px) and(max-width: 769px) {
            padding-bottom: 10vh;
        }
        @media screen and (max-width: 576px) {
            height: 300px;
            margin-top: 100px;
            font-size: 10px;
            padding-bottom: 180px;
        }
        @media screen and (max-width: 391px) {
            padding-bottom: 300px;
            max-height: 80vh;
        }
        @media screen and (min-width: 770px) and (max-width: 1024px) and (max-height: 900px) {
            min-height: 0;
            height: 90vh;
        }
        @media screen and (max-width: 769px) {
            margin-top: 100px;
            font-size: 10px;
            min-height: 0;
            height: 70vh;
            padding: 12px;
            overflow: auto;
        }
        @media screen and (max-width: 570px) {
            height: auto !important;
            // min-height: 70vh;
            min-height: 400px;
        }
        @media screen and (min-width: 575px) and (max-width: 769px) {
            margin-top: 70px;
        }

        @media screen and (min-width: 1601px) {
            animation: cardDescrXl 0.75s ease;
        }
        @media screen and (max-width: 1600px) {
            animation: cardDescrMd 0.75s ease;
        }
        @media screen and (max-width: 645px) {
            animation: cardDescrSm 0.75s ease;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            overflow: auto;
        }

        &.removed {
            @include animation-base;
            @media screen and (min-width: 1601px) {
                animation: cardDescrBackXl 0.5s ease;
            }
            @media screen and (max-width: 1600px) {
                animation: cardDescrBackMd 0.5s ease;
            }
            @media screen and (max-width: 645px) {
                animation: cardDescrBackSm 0.5s ease;
            }
            animation-fill-mode: forwards;
        }
    }
    &__txt {
        @include animation-base;
        opacity: 0;
        transform: translateY(5px);
        .hire-page-description_active & {
            opacity: 1;
            transform: none;
        }
        &__header {
            font-style: normal;
            font-weight: 300;
            font-size: 28px;
            line-height: 99.5%;
            letter-spacing: 0.02em;
            margin-bottom: 65px;
            @media screen and (max-width: 576px) {
                margin-bottom: 25px;
                font-size: 20px;
            }

            @media screen and (max-width: 1600px) {
                margin-bottom: 35px;
            }
        }
        &__requirements {
            margin-bottom: 30px;
            @media screen and (max-width: 576px) {
                margin-bottom: 10px;
            }
            &-header {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 180%;
                letter-spacing: 0.04em;
                @media screen and (max-width: 576px) {
                    font-size: 10px;
                }
                @media screen and (min-width: 1025px) and (max-width: 1600px) {
                    font-size: 13px;
                }
                @media screen and (max-width: 376px) {
                    font-size: 8px;
                }
            }
            &-list {
                &-item {
                    display: flex;
                    align-items: center;
                    > .dot {
                        min-width: 4px;
                        min-height: 4px;
                        background: white;
                        margin: 0 10px;
                        border-radius: 50%;
                    }
                    > .text {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 160%;
                        @media screen and (max-width: 576px) {
                            font-size: 9px;
                        }
                        @media screen and (min-width: 1025px) and (max-width: 1600px) {
                            font-size: 12px;
                        }
                        @media screen and (max-width: 376px) {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
        &__about {
            margin-bottom: 30px;
            @media screen and (max-width: 576px) {
                margin-bottom: 10px;
            }
            &-header {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 180%;
                letter-spacing: 0.04em;
                @media screen and (max-width: 576px) {
                    font-size: 10px;
                }
                @media screen and (min-width: 1025px) and (max-width: 1600px) {
                    font-size: 13px;
                }
                @media screen and (max-width: 376px) {
                    font-size: 9px;
                }
            }
            &-content {
                text-indent: 20px;
                font-weight: 400;
                font-size: 13px;
                line-height: 180%;
                letter-spacing: 0.04em;
                @media screen and (max-width: 576px) {
                    font-size: 9px;
                }
                @media screen and (min-width: 1025px) and (max-width: 1600px) {
                    font-size: 12px;
                }
                @media screen and (max-width: 376px) {
                    font-size: 8px;
                }
            }
        }
        &__duties {
            margin-bottom: 30px;
            @media screen and (max-width: 576px) {
                margin-bottom: 10px;
            }
            &-header {
                font-weight: 600;
                font-size: 14px;
                line-height: 180%;
                @media screen and (max-width: 576px) {
                    font-size: 10px;
                }
                @media screen and (min-width: 1025px) and (max-width: 1600px) {
                    font-size: 13px;
                }
                @media screen and (max-width: 376px) {
                    font-size: 9px;
                }
            }
            &-list {
                &-item {
                    display: flex;
                    align-items: center;
                    > .dot {
                        width: 2px;
                        height: 2px;
                        background: white;
                        margin: 0 10px;
                    }
                    > .text {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 180%;
                        letter-spacing: 0.04em;
                        @media screen and (max-width: 576px) {
                            font-size: 9px;
                        }
                        @media screen and (min-width: 1025px) and (max-width: 1600px) {
                            font-size: 12px;
                        }
                        @media screen and (max-width: 376px) {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
    }
    &__links {
        display: flex;
        flex-wrap: wrap;
        @include animation-base;
        opacity: 0;
        transform: translateX(5px);
        .hire-page-description_active & {
            opacity: 1;
            transform: none;
        }
    }
    &__bottom {
        height: 25px;
        margin-top: 35px;
        @media screen and (max-width: 576px) {
            margin-top: 30px;
        }
        display: flex;
        justify-content: space-between;
        &-company {
            @include background-image(logo);
            background-size: contain;
            width: 100px;
        }
        &-block {
            gap: 16px;
            display: flex;
            flex-direction: row;

            a {
                color: white;
                text-decoration: none;
            }

            &__type {
                padding: 0px 10px;
                line-height: 25px;
                border: 1px solid #ffffff;
                border-radius: 19px;
                text-align: center;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
    .link {
        margin-top: 30px;
        margin-right: 30px;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
    }
}
