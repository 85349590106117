@font-face {
    font-family: mont-reg;
    src: url('../fonts/mont-reg.ttf') format('truetype');
}
@font-face {
    font-family: mont-med;
    src: url('../fonts/mont-med.ttf') format('truetype');
}
@font-face {
    font-family: mach-light;
    src: url('../fonts/mach-light.otf') format('opentype');
}
@font-face {
    font-family: mach-med;
    src: url('../fonts/mach-med.ttf') format('truetype');
}
@font-face {
    font-family: Montserrat;
    src: url('../fonts/montserrat-medium.ttf') format('truetype');
}

$font-main: 'mont-reg', sans-serif;
$font-main-medium: 'mont-med', sans-serif;
$font-second-light: 'mach-light', sans-serif;
$font-second-med: 'mach-med', sans-serif;

@mixin setResponsiveSize($v1, $v2, $v3, $v4) {
    font-size: $v1;

    @include md {
        font-size: $v2;
    }
    @include lg {
        font-size: $v3;
    }
    @include xl {
        font-size: $v4;
    }
}

@mixin setResponsiveLineHeight($v1, $v2, $v3, $v4) {
    line-height: $v1;
    @include md {
        line-height: $v2;
    }
    @include lg {
        line-height: $v3;
    }
    @include xl {
        line-height: $v4;
    }
}

@mixin t-reg-10 {
    font-family: $font-main;
    @include setResponsiveSize(10px, 10px, 10px, 10px);
    @include setResponsiveLineHeight(1.2, 1.2, 1.2, 1.2);
}

@mixin t-reg {
    font-family: $font-main;
    @include setResponsiveSize(14px, 14px, 14px, 14px);
    @include setResponsiveLineHeight(1.2, 1.2, 1.2, 1.2);
}

@mixin t-reg-34 {
    font-family: $font-main;
    @include setResponsiveSize(24px, 26px, 28px, 34px);
    @include setResponsiveLineHeight(1.2, 1.2, 1.2, 1.2);
}

@mixin t-neo-light-14 {
    font-family: $font-second-light;
    @include setResponsiveSize(14px, 14px, 14px, 14px);
    @include setResponsiveLineHeight(1.2, 1.2, 1.2, 1.2);
}

@mixin t-neo-light-20 {
    font-family: $font-second-light;
    @include setResponsiveSize(16px, 18px, 20px, 20px);
    @include setResponsiveLineHeight(1.2, 1.2, 1.2, 1.2);
}

@mixin t-neo-light-28 {
    font-family: $font-second-light;
    @include setResponsiveSize(20px, 22px, 24px, 28px);
    @include setResponsiveLineHeight(1.2, 1.2, 1.2, 1.2);
}

@mixin t-header-1 {
    font-family: $font-second-med;
    @include setResponsiveSize(30px, 34px, 40px, 52px);
    @include setResponsiveLineHeight(1, 1, 1, 1);
}
