@use 'sass:math';
@import '_bootstrap';

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1400px,
    xxl: 1700px
) !default;

$grid-columns: 12;
$grid-gutter-width: $baseUnit * 4;

.container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    @include box-sizing;
    padding-left: $baseUnit * 2;
    padding-right: $baseUnit * 2;
    @include md {
        padding-left: $baseUnit * 3;
        padding-right: $baseUnit * 3;
    }

    @include lg {
        padding-left: $baseUnit * 6;
        padding-right: $baseUnit * 6;
    }
    @include xl {
        padding-left: $baseUnit * 8;
        padding-right: $baseUnit * 8;
    }
}

.row {
    @include flexbox;
    @include flex-wrap(wrap);
    margin-left: $baseUnit * -2;
    margin-right: $baseUnit * -2;
    @include md {
        margin-left: $baseUnit * -3;
        margin-right: $baseUnit * -3;
    }
    
   @include xl {
        margin-left: $baseUnit * -8;
        margin-right: $baseUnit * -8;
    }
}

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 math.percentage(math.div($size, $columns));
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: math.percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: math.div($size, $columns);
    margin-left: if($num == 0, 0, percentage($num));
}

@include make-grid-columns();

.no-gutters-md {
    @include md {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}
.row-lg-gutters {
    
    @media all and (min-width: 1024px) and (max-width: 1400px) {
        padding-left: math.div(100%, 12) * 0.5;
        padding-right: math.div(100%, 12) * 0.5;
    }
}
